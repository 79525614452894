import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { toast } from "react-toastify";
import UIKit from "uikit";

import Swal from "sweetalert2";
import CHANGE_PASSWORD_MUTATION from "../../mutations/auth/changePassword";

const ChangePasswordSchema = yup.object().shape({
	opassword: yup.string().required("Old Password is required"),
	npassword: yup
		.string()
		.min(8, "New Password must contain at least 8 characters")
		.matches(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/,
			"New Password must contain at least 1 uppercase, lowercase character and number"
		)
		.required("New Password is required"),
	cpassword: yup
		.string()
		.oneOf([yup.ref("npassword"), null], "Password does not match!")
		.required("Confirm Password is required"),
});

const Nav = () => {
	const ftpURL = "https://ftp.xero.foodzaps.com:21";
	const [user, setUser] = useState({});
	const [reveal, setReveal] = useState(false);

	UIKit.util.on("#ftp-modal", "hide", () => {
		setReveal(false);
	});

	useEffect(() => {
		const {
			user: { email, organisation },
		} = JSON.parse(localStorage.getItem("user"));

		setUser({ email, organisation });
	}, []);

	const logout = () => {
		localStorage.removeItem("user");
		window.location = "/sign-in";
	};

	const copyToClipboard = (text) => {
		const textField = document.createElement("textarea");
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
		toast.info("Copied!");
	};

	const { handleSubmit, register, errors } = useForm({
		mode: "onBlur",
		resolver: yupResolver(ChangePasswordSchema),
	});

	const [doChangePassword] = useMutation(CHANGE_PASSWORD_MUTATION, {
		onCompleted: (data) => {
			Swal.fire({
				icon: "success",
				title: "Password Changed!",
			}).then(() => {
				localStorage.setItem("user", JSON.stringify(data.changePassword));
				window.location = "/";
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				const {
					extensions: {
						exception: {
							data: { message },
						},
					},
				} = graphQLErrors[0];

				Swal.fire({
					icon: "error",
					title: "Unable to change password",
					text: message[0].messages[0].message,
				});
			}
		},
	});

	const onSubmit = (values) => {
		const {
			user: { id },
		} = JSON.parse(localStorage.getItem("user"));

		Swal.fire({
			title: "Changing Password",
			allowOutsideClick: false,
			allowEscapeKey: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
			onOpen: () => {
				doChangePassword({
					variables: {
						input: { userId: id, ...values },
					},
				});
			},
		});
	};

	return (
		<header id="top-head" className="uk-position-fixed">
			<div className="uk-container uk-container-expand">
				<nav className="uk-navbar" data-uk-navbar="mode:hover; duration: 250">
					<div className="uk-navbar-right">
						<ul className="uk-navbar-nav">
							<li>
								<a className="user-nav" href="#">
									<i className="uk-margin-small-right" uk-icon="icon: user" />
									<div className="uk-flex uk-flex-column">
										<span>{user.email}</span>
										<small>{user.organisation ? user.organisation.name : ""}</small>
									</div>
								</a>
								<div class="uk-navbar-dropdown">
									<ul class="uk-nav uk-navbar-dropdown-nav">
										<li>
											<a uk-toggle="#change-password-modal">
												<i className="uk-margin-small-right" uk-icon="icon: lock" />
												Change Password
											</a>
										</li>
										<li>
											<a uk-toggle="#ftp-modal">
												<i
													className="uk-margin-small-right"
													uk-icon="icon: database"
												/>
												FTP Credentials
											</a>
										</li>
										<li>
											<a onClick={() => logout()}>
												<i
													className="uk-margin-small-right"
													uk-icon="icon: sign-out"
												/>
												Logout
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</nav>
				<div id="ftp-modal" data-uk-modal>
					<div className="uk-modal-dialog uk-modal-body">
						<h2 className="uk-modal-title">FTP Credentials</h2>
						<div className="uk-grid-small" data-uk-grid>
							{user.organisation && (
								<dl className="uk-description-list">
									<dt>Server</dt>
									<dd className="uk-flex uk-flex-middle">
										{ftpURL}
										<span
											className="uk-margin-small-left copy-icon"
											uk-icon="icon: fa-regular-copy; ratio: 0.8"
											uk-tooltip="Copy to Clipboard"
											onClick={() => copyToClipboard(ftpURL)}
										></span>
									</dd>
									<dt>Username</dt>
									<dd className="uk-flex uk-flex-middle">
										{user.organisation.FTP.user}
										<span
											className="uk-margin-small-left copy-icon"
											uk-icon="icon: fa-regular-copy; ratio: 0.8"
											uk-tooltip="Copy to Clipboard"
											onClick={() => copyToClipboard(user.organisation.FTP.user)}
										></span>
									</dd>
									<dt>Password</dt>
									<dd className="uk-flex uk-flex-middle">
										{!reveal ? "***************" : user.organisation.FTP.password}
										{reveal && (
											<span
												className="uk-margin-small-left copy-icon"
												uk-icon="icon: fa-regular-copy; ratio: 0.8"
												uk-tooltip="Copy to Clipboard"
												onClick={() =>
													copyToClipboard(user.organisation.FTP.password)
												}
											></span>
										)}
										{!reveal ? (
											<span
												className="uk-margin-small-left reveal-icon"
												uk-icon="icon: fa-solid-eye; ratio: 0.8"
												onClick={() => setReveal(true)}
											></span>
										) : (
											<span
												className="uk-margin-small-left reveal-icon"
												uk-icon="icon: fa-solid-eye-slash; ratio: 0.8"
												onClick={() => setReveal(false)}
											></span>
										)}
									</dd>
								</dl>
							)}
						</div>
					</div>
				</div>
				<div id="change-password-modal" data-uk-modal>
					<div className="uk-modal-dialog uk-modal-body">
						<h2 className="uk-modal-title">Change Password</h2>
						<form onSubmit={handleSubmit(onSubmit)}>
							<fieldset className="uk-fieldset">
								<div className="uk-margin-small">
									<div className="uk-inline uk-width-1-1">
										<span
											className="uk-form-icon uk-form-icon-flip"
											data-uk-icon="icon: lock"
										></span>
										<input
											className="uk-input uk-border-pill"
											name="opassword"
											placeholder="Old Password"
											type="password"
											ref={register}
										/>
									</div>
									{errors.opassword && (
										<p className="validation">{errors.opassword.message}</p>
									)}
								</div>
								<div className="uk-margin-small">
									<div className="uk-inline uk-width-1-1">
										<span
											className="uk-form-icon uk-form-icon-flip"
											data-uk-icon="icon: lock"
										></span>
										<input
											className="uk-input uk-border-pill"
											name="npassword"
											placeholder="New Password"
											type="password"
											autocomplete="new-password"
											ref={register}
										/>
									</div>
									{errors.npassword && (
										<p className="validation">{errors.npassword.message}</p>
									)}
								</div>
								<div className="uk-margin-small">
									<div className="uk-inline uk-width-1-1">
										<span
											className="uk-form-icon uk-form-icon-flip"
											data-uk-icon="icon: lock"
										></span>
										<input
											className="uk-input uk-border-pill"
											name="cpassword"
											placeholder="Confirm Password"
											type="password"
											ref={register}
										/>
									</div>
									{errors.cpassword && (
										<p className="validation">{errors.cpassword.message}</p>
									)}
								</div>
								<div className="uk-margin">
									<button
										type="submit"
										className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
									>
										Change Password
									</button>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Nav;
