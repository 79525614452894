import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import Swal from "sweetalert2";

import REGISTER_MUTATION from "../../mutations/auth/register";

const RegisterSchema = yup.object().shape({
	organisation: yup.string().required("Organisation is required"),
	email: yup.string().required("Email Address is required"),
	password: yup
		.string()
		.min(8, "Password must contain at least 8 characters")
		.matches(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/,
			"Password must contain at least 1 uppercase, lowercase character and number"
		)
		.required("Password is required"),
	cpassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "Password does not match!")
		.required("Confirm Password is required"),
});

function Register(props) {
	const { handleSubmit, register, errors } = useForm({
		mode: "onBlur",
		resolver: yupResolver(RegisterSchema),
	});

	const [doRegister] = useMutation(REGISTER_MUTATION, {
		onCompleted: (data) => {
			// Swal.close();
			Swal.fire({
				icon: "success",
				title: "Registration Success!",
				text: "Please verify your email address",
			}).then(() => {
				window.location = "/";
			});
			// localStorage.setItem("user", JSON.stringify(data.register));
			// window.location = "/";
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				const {
					extensions: {
						exception: {
							data: { message },
						},
					},
				} = graphQLErrors[0];

				Swal.fire({
					icon: "error",
					title: "Unable to register",
					text: message[0].messages[0].message,
				});
			}
		},
	});

	const onSubmit = (values) => {
		const { organisation, email, password } = values;
		Swal.fire({
			title: "Signing Up...",
			allowOutsideClick: false,
			allowEscapeKey: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
			onOpen: () => {
				doRegister({
					variables: {
						input: { organisation, username: email, email, password },
					},
				});
			},
		});
	};

	return (
		<div className="register uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden">
			<div className="uk-position-cover uk-overlay-default"></div>
			<div
				className="uk-width-large uk-padding-small uk-position-z-index"
				uk-scrollspy="cls: uk-animation-fade"
			>
				<div className="uk-card uk-card-default uk-card-body uk-border-rounded register-card">
					<div className="uk-text-center uk-margin-medium">
						<img width="100%" src="/img/foodzaps.jpg" alt="Logo" />
					</div>
					<form className="toggle-class" onSubmit={handleSubmit(onSubmit)}>
						<fieldset className="uk-fieldset">
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: fa-solid-globe"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="organisation"
										placeholder="Organisation"
										type="text"
										ref={register}
									/>
								</div>
								{errors.organisation && (
									<p className="validation">{errors.organisation.message}</p>
								)}
							</div>
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: user"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="email"
										placeholder="Email Address"
										type="text"
										ref={register}
									/>
								</div>
								{errors.email && (
									<p className="validation">{errors.email.message}</p>
								)}
							</div>
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: lock"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="password"
										placeholder="Password"
										type="password"
										autocomplete="new-password"
										ref={register}
									/>
								</div>
								{errors.password && (
									<p className="validation">{errors.password.message}</p>
								)}
							</div>
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: lock"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="cpassword"
										placeholder="Confirm Password"
										type="password"
										ref={register}
									/>
								</div>
								{errors.cpassword && (
									<p className="validation">{errors.cpassword.message}</p>
								)}
							</div>
							<div className="uk-margin">
								<button
									type="submit"
									className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
								>
									SIGN UP
								</button>
								<div className="uk-width-1-1 uk-flex uk-flex-center uk-margin-top">
									<a href="/sign-in">Already have an account? Sign In</a>
								</div>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Register;
