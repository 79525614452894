import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import Swal from "sweetalert2";
import FORGOT_PASSWORD_MUTATION from "../../mutations/auth/forgotPassword";

const ForgotPasswordSchema = yup.object().shape({
	email: yup.string().required("Email Address is required"),
});

function ForgetPassword(props) {
	const { handleSubmit, register, errors } = useForm({
		mode: "onBlur",
		resolver: yupResolver(ForgotPasswordSchema),
	});

	const [doForgotPassword] = useMutation(FORGOT_PASSWORD_MUTATION, {
		onCompleted: (data) => {
			Swal.fire({
				icon: "success",
				title: "Reset Password Email Sent!",
			}).then(() => {
				window.location = "/";
			});
		},
		onError: ({ graphQLErrors }) => {
			console.log(graphQLErrors);
			if (graphQLErrors && graphQLErrors.length > 0) {
				const {
					extensions: {
						exception: {
							data: { message },
						},
					},
				} = graphQLErrors[0];

				Swal.fire({
					icon: "error",
					title: "Invalid Account",
					// text: message[0].messages[0].message,
				});
			}
		},
	});

	const onSubmit = ({ email }) => {
		Swal.fire({
			title: "Sending Reset Password Link",
			allowOutsideClick: false,
			allowEscapeKey: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
			onOpen: () => {
				doForgotPassword({
					variables: { email },
				});
			},
		});
	};

	return (
		<div className="register uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden">
			<div className="uk-position-cover uk-overlay-default"></div>
			<div
				className="uk-width-large uk-padding-small uk-position-z-index"
				uk-scrollspy="cls: uk-animation-fade"
			>
				<div className="uk-card uk-card-default uk-card-body uk-border-rounded register-card">
					<div className="uk-text-center uk-margin-medium">
						<img width="75%" src="/img/login-logo.svg" alt="Logo" />
					</div>
					<form className="toggle-class" onSubmit={handleSubmit(onSubmit)}>
						<fieldset className="uk-fieldset">
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: user"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="email"
										placeholder="Email Address"
										type="text"
										ref={register}
									/>
								</div>
								{errors.email && (
									<p className="validation">{errors.email.message}</p>
								)}
							</div>
							<div className="uk-margin">
								<button
									type="submit"
									className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
								>
									Request Reset Password
								</button>
								<div className="uk-width-1-1 uk-flex uk-flex-center uk-margin-top">
									<a href="/sign-in">Back to Sign In</a>
								</div>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	);
}

export default ForgetPassword;
