import { gql } from "@apollo/client";

const COMPANIES_QUERY = gql`
  query getCompanies($organisation: ID!) {
    companies(where: { organisation: $organisation }) {
      id
      name
      PayboyAccessToken
      XeroAccessToken
      POS {
        id
        name
        posId
        createdAt
      }
      employees {
        id
        name
        employeeId
        createdAt
      }
      updatedAt
    }
  }
`;

export default COMPANIES_QUERY;
