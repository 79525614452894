import React from "react";

const Authorized = () => {
  return (
    <div className="login uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden">
      <div className="uk-position-cover uk-overlay-default"></div>
      <div
        className="uk-width-1-2 uk-padding-small uk-position-z-index"
        uk-scrollspy="cls: uk-animation-fade"
      >
        <div className="uk-text-center uk-margin">
          <img width="350" src="/img/login-logo.svg" alt="Logo" />
        </div>
        <div className="uk-card uk-card-small uk-card-default auth-card">
          <div className="uk-card-header">
            <h3 className="uk-card-title uk-text-center uk-margin-remove-bottom">
              Success!
            </h3>
            <p className="uk-text-center uk-margin-remove-top uk-margin-remove-bottom">
              Authorization was successful
            </p>
            <p className="uk-text-center uk-margin-remove-top uk-text-bold">
              You can safely close this window
            </p>
          </div>
          <div className="uk-card-body">
            <p>You have granted access to:</p>
            <p className="uk-text-bold">Organisation Data</p>
            <div
              className="uk-grid-small uk-flex uk-flex-middle uk-margin-bottom"
              uk-grid
            >
              <div className="uk-width-auto">
                <span
                  className="access-icon uk-border-pill"
                  uk-icon="icon: fa-solid-receipt; ratio: 0.8"
                ></span>
              </div>
              <div className="uk-width-expand">
                <h3 className="access-header uk-margin-remove-bottom">
                  Business Transactions
                </h3>
                <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom">
                  View and Manage
                </p>
              </div>
            </div>
            <div
              className="uk-grid-small uk-flex uk-flex-middle uk-margin-bottom"
              uk-grid
            >
              <div className="uk-width-auto">
                <span
                  className="access-icon uk-border-pill"
                  uk-icon="icon: fa-solid-users; ratio: 0.8"
                ></span>
              </div>
              <div className="uk-width-expand">
                <h3 className="access-header uk-margin-remove-bottom">
                  Business Contacts
                </h3>
                <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom">
                  View and Manage
                </p>
              </div>
            </div>
            <p className="uk-text-bold">User Account Information</p>
            <div
              className="uk-grid-small uk-flex uk-flex-middle uk-margin-bottom"
              uk-grid
            >
              <div className="uk-width-auto">
                <span
                  className="access-icon uk-border-pill"
                  uk-icon="icon: fa-solid-user; ratio: 0.8"
                ></span>
              </div>
              <div className="uk-width-expand">
                <h3 className="access-header uk-margin-remove-bottom">
                  Profile Details
                </h3>
                <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom">
                  View your name, email and user profile
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorized;
