import { gql } from "@apollo/client";

const DELETE_POS_MUTATION = gql`
  mutation DeletePOS($id: ID!) {
    deletePo(input: { where: { id: $id } }) {
      po {
        id
        name
        posId
      }
    }
  }
`;

export default DELETE_POS_MUTATION;
