import { gql } from "@apollo/client";

const REGISTER_MUTATION = gql`
	mutation Register($input: UsersPermissionsRegisterInput!) {
		register(input: $input) {
			jwt
			user {
				id
				username
				email
				organisation {
					id
					name
					FTP
				}
			}
		}
	}
`;

export default REGISTER_MUTATION;
