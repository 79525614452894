import { gql } from "@apollo/client";

const DELETE_COMPANY_MUTATION = gql`
  mutation DeleteCompany($companyId: ID!) {
    deleteCompany(input: { where: { id: $companyId } }) {
      company {
        id
        name
      }
    }
  }
`;

export default DELETE_COMPANY_MUTATION;
