import { gql } from "@apollo/client";

const ADD_POS_MUTATION = gql`
  mutation AddPOS($companyId: ID!, $name: String!, $posId: String!) {
    createPo(
      input: { data: { company: $companyId, name: $name, posId: $posId } }
    ) {
      po {
        id
        name
        posId
      }
    }
  }
`;

export default ADD_POS_MUTATION;
