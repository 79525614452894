import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { TextInput } from "../../components/Form";
import * as yup from "yup";
import { useMutation, useLazyQuery } from "@apollo/client";
import Moment from "react-moment";

import Swal from "sweetalert2";
import UIkit from "uikit";
import CREATE_COMPANY_MUTATION from "../../mutations/company/company";
import COMPANIES_QUERY from "../../queries/company/companies";
import EDIT_COMPANY_MUTATION from "../../mutations/company/edit";
import EDIT_PAYBOY_ACCESS_MUTATION from "../../mutations/company/payboyAccess";
import DELETE_COMPANY_MUTATION from "../../mutations/company/delete";
import REQUEST_XERO_MUTATION from "../../mutations/company/requestXero";

const NewCompanySchema = yup.object().shape({
	name: yup.string().required("Branch Name is required"),
});

const AccessTokenSchema = yup.object().shape({
	access_token: yup.string().required("Access Token is required"),
});

const Company = () => {
	const [organisation, setOrganisation] = useState({});
	const [editTarget, setEditTarget] = useState();
	const [editToken, setEditToken] = useState(false);
	const { handleSubmit, register, errors, reset } = useForm({
		defaultValues: {
			name: "",
			email: "",
		},
		mode: "onBlur",
		resolver: yupResolver(NewCompanySchema),
	});

	const {
		handleSubmit: handleTokenSubmit,
		register: tokenRegister,
		errors: tokenErrors,
		reset: tokenReset,
	} = useForm({
		defaultValues: {
			access_token: "",
		},
		mode: "onBlur",
		resolver: yupResolver(AccessTokenSchema),
	});

	const columns = [
		{
			name: "",
			width: "80px",
			center: true,
			cell: (row) => (
				<button
					uk-tooltip="Edit Branch"
					className="uk-icon-button uk-button-primary"
					uk-icon="icon: fa-solid-edit; ratio: 0.6"
					onClick={() => openEditModal(row.id)}
				/>
			),
		},
		{ name: "Branch Name", selector: "name", sortable: true },
		// { name: "Contact Email", selector: "email", sortable: true },
		// {
		//   name: "POS / Employees",
		//   cell: (row) => (
		//     <Link
		//       className="uk-button uk-button-primary uk-button-small uk-width-1-1"
		//       to={`/${row.id}/pos`}
		//     >
		//       Manage
		//     </Link>
		//   ),
		// },
		{
			name: "Payboy Access Token",
			width: "300px",
			selector: "PayboyAccessToken",
			cell: (row) => {
				if (!row.PayboyAccessToken)
					return (
						<button
							className="uk-button uk-button-primary uk-button-small uk-width-1-1"
							onClick={() => openTokenModal(row.id, false)}
						>
							Add
						</button>
					);
				else
					return (
						<span>
							{row.PayboyAccessToken}{" "}
							<button
								uk-tooltip="Edit Token"
								className="uk-icon-button uk-margin-small-left uk-button-primary"
								uk-icon="icon: fa-solid-edit; ratio: 0.6"
								onClick={() => {
									setEditToken(true);
									openTokenModal(row.id, true);
								}}
							/>
						</span>
					);
			},
		},
		{
			name: "Xero Access Token",
			selector: "XeroAccessToken",
			cell: (row) => {
				if (!row.XeroAccessToken)
					return (
						<button
							className="uk-button uk-button-primary uk-button-small uk-width-1-1"
							onClick={() => {
								window.open(
									`${window.location.origin}/xero/auth/request/${row.id}`,
									"_blank",
									"toolbar=0,location=0,menubar=0,width=1000,height=800"
								);
								// Swal.fire({
								//   title: `Requesting Xero Accounting Authorization`,
								//   allowOutsideClick: false,
								//   allowEscapeKey: false,
								//   onBeforeOpen: () => {
								//     Swal.showLoading();
								//   },
								//   onOpen: () => {
								//     // requestXero({ variables: { companyId: row.id } });
								//   },
								// });
							}}
						>
							Request
						</button>
					);
				else
					return (
						<span className="uk-label uk-width-1-1 uk-flex uk-flex-middle uk-flex-center auth-label">
							Authorized
						</span>
					);
			},
		},
		{
			name: "Last Updated",
			selector: "updatedAt",
			sortable: true,
			cell: (row) => <Moment format="DD/MM/YYYY HH:mm">{row.updatedAt}</Moment>,
		},
		{
			name: "",
			width: "80px",
			cell: (row) => (
				<button
					uk-tooltip="Delete Company"
					className="uk-icon-button uk-button-danger"
					uk-icon="icon: fa-solid-trash; ratio: 0.6"
					onClick={() => deleteCompany({ variables: { companyId: row.id } })}
				/>
			),
		},
	];

	const [getCompanies, { data, refetch }] = useLazyQuery(COMPANIES_QUERY);

	useEffect(() => {
		const {
			user: { organisation },
		} = JSON.parse(localStorage.getItem("user"));
		getCompanies({ variables: { organisation: organisation.id } });
		setOrganisation(organisation);

		return () => {
			try {
				UIkit.modal("#pos-modal").$destroy(true);
			} catch (err) {}
		};
	}, []);

	const [createCompany] = useMutation(CREATE_COMPANY_MUTATION, {
		onCompleted: ({ createCompany: { company } }) => {
			Swal.fire({
				icon: "success",
				title: `${company.name} Created!`,
			}).then(() => {
				refetch();
				reset();
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors[0].message !== "Forbidden")
				Swal.fire({
					icon: "error",
					title: `Unable to create Branch`,
					text: graphQLErrors[0].message,
				});
		},
	});

	const [editCompany] = useMutation(EDIT_COMPANY_MUTATION, {
		onCompleted: ({ updateCompany: { company } }) => {
			Swal.fire({
				icon: "success",
				title: `${company.name} Edited!`,
			}).then(() => {
				refetch();
				reset();
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors[0].message !== "Forbidden")
				Swal.fire({
					icon: "error",
					title: `Unable to edit Branch`,
					text: graphQLErrors[0].message,
				});
		},
	});

	const [deleteCompany] = useMutation(DELETE_COMPANY_MUTATION, {
		onCompleted: ({ deleteCompany: { company } }) => {
			Swal.fire({
				icon: "success",
				title: `${company.name} Removed!`,
			}).then(() => {
				refetch();
				reset();
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors[0].message !== "Forbidden")
				Swal.fire({
					icon: "error",
					title: `Unable to remove Branch`,
					text: graphQLErrors[0].message,
				});
		},
	});

	const [requestXero] = useMutation(REQUEST_XERO_MUTATION, {
		onCompleted: ({ requestXero }) => {
			if (requestXero === "success")
				Swal.fire({
					icon: "success",
					title: "Xero Accounting Authorization Requested!",
				});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors[0].message !== "Forbidden")
				Swal.fire({
					icon: "error",
					title: `Unable to request Authorization`,
					text: graphQLErrors[0].message,
				});
		},
	});

	const onSubmit = (values) => {
		Swal.fire({
			title: `${!editTarget ? "Creating" : "Editing"} Branch`,
			allowOutsideClick: false,
			allowEscapeKey: false,
			onBeforeOpen: () => {
				closeCompanyModal();
				Swal.showLoading();
			},
			onOpen: () => {
				if (!editTarget)
					createCompany({
						variables: { ...values, organisation: organisation.id },
					});
				else
					editCompany({
						variables: {
							companyId: editTarget,
							...values,
							organisation: organisation.id,
						},
					});
			},
		});
	};

	const closeCompanyModal = () => {
		UIkit.modal("#company-modal").hide();
		reset();
		setEditTarget(undefined);
	};

	const openEditModal = (companyId) => {
		if (data) {
			const { companies } = data;
			const target = companies.find((company) => company.id === companyId);
			reset({
				name: target.name,
				email: target.email,
			});
			setEditTarget(target.id);
			UIkit.modal("#company-modal").show();
		}
	};

	const openTokenModal = (companyId, edit) => {
		if (data) {
			const { companies } = data;
			const target = companies.find((company) => company.id === companyId);
			if (edit)
				tokenReset({
					access_token: target.PayboyAccessToken,
				});
			setEditTarget(target.id);
			UIkit.modal("#payboy-access-modal").show();
		}
	};

	const closeTokenModal = () => {
		UIkit.modal("#payboy-access-modal").hide();
		tokenReset();
		setEditTarget(false);
	};

	const [managePayboyAccess] = useMutation(EDIT_PAYBOY_ACCESS_MUTATION, {
		onCompleted: ({ updateCompany: { company } }) => {
			Swal.fire({
				icon: "success",
				title: `Payboy Access Token ${!editToken ? "Added" : "Edited"}`,
			}).then(() => {
				refetch();
				tokenReset();
				setEditToken(false);
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors[0].message !== "Forbidden")
				Swal.fire({
					icon: "error",
					title: `Unable to ${!editToken ? "add" : "edit"} Payboy Access Token`,
					text: graphQLErrors[0].message,
				});
		},
	});

	const onTokenSubmit = (values) => {
		Swal.fire({
			title: `${!editToken ? "Adding" : "Editing"} Payboy Access Token`,
			allowOutsideClick: false,
			allowEscapeKey: false,
			onBeforeOpen: () => {
				closeTokenModal();
				Swal.showLoading();
			},
			onOpen: () => {
				managePayboyAccess({ variables: { companyId: editTarget, ...values } });
				// if (!editToken) createCompany({ variables: { ...values } });
				// else editCompany({ variables: { companyId: editTarget, ...values } });
			},
		});
	};

	return (
		<div>
			<div>
				<h1 className="page-header uk-flex uk-flex-middle uk-margin-medium-bottom">
					Branch
					<button
						uk-tooltip="New Branch"
						className="uk-icon-button uk-button-primary uk-margin-left"
						uk-icon="icon: fa-solid-plus; ratio: 0.8"
						uk-toggle="#company-modal"
					/>
				</h1>
				<div className="uk-card uk-card-default uk-card-body">
					<DataTable
						columns={columns}
						data={data ? data.companies : []}
						noHeader={true}
						highlightOnHover={true}
						striped={true}
						pagination={true}
					/>
				</div>
			</div>
			<div id="company-modal" uk-modal="bg-close:false; esc-close:false">
				<div className="uk-modal-dialog uk-modal-body">
					<h2 className="uk-modal-title">{!editTarget ? "New" : "Edit"} Branch</h2>
					<form className="uk-form-stacked" onSubmit={handleSubmit(onSubmit)}>
						<TextInput
							label="Branch Name"
							name="name"
							type="text"
							rules={register}
							errors={errors.name}
						/>
						<p className="uk-text-right uk-margin-medium-top">
							<button
								className="uk-button uk-button-default"
								type="button"
								onClick={closeCompanyModal}
							>
								Cancel
							</button>
							<button className="uk-button uk-button-primary" type="submit">
								{!editTarget ? "Create" : "Edit"}
							</button>
						</p>
					</form>
				</div>
			</div>
			<div id="payboy-access-modal" uk-modal="bg-close:false; esc-close:false">
				<div className="uk-modal-dialog uk-modal-body">
					<h2 className="uk-modal-title">
						{!editToken ? "Add" : "Edit"} Payboy Access Token
					</h2>
					<form
						className="uk-form-stacked"
						onSubmit={handleTokenSubmit(onTokenSubmit)}
					>
						<TextInput
							label="Access Token"
							name="access_token"
							type="text"
							rules={tokenRegister}
							errors={tokenErrors.access_token}
						/>
						<p className="uk-text-right uk-margin-medium-top">
							<button
								className="uk-button uk-button-default"
								type="button"
								onClick={closeTokenModal}
							>
								Cancel
							</button>
							<button className="uk-button uk-button-primary" type="submit">
								{!editToken ? "Add" : "Edit"}
							</button>
						</p>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Company;
