import { gql } from "@apollo/client";

const DELETE_EMPLOYEE_MUTATION = gql`
  mutation DeleteEmployee($id: ID!) {
    deleteEmployee(input: { where: { id: $id } }) {
      employee {
        id
        name
        employeeId
      }
    }
  }
`;

export default DELETE_EMPLOYEE_MUTATION;
