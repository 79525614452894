import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import SelectSearch from "react-select-search/dist/cjs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { TextInput } from "../../components/Form";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";

import Swal from "sweetalert2";
import UIkit from "uikit";
import COMPANIES_QUERY from "../../queries/company/companies";
import ADD_POS_MUTATION from "../../mutations/pos/pos";
import EDIT_POS_MUTATION from "../../mutations/pos/edit";
import DELETE_POS_MUTATION from "../../mutations/pos/delete";

const AddPOSSchema = yup.object().shape({
	name: yup.string().required("POS Name is required"),
	posId: yup.string().required("POS ID is required"),
});

const POS = (props) => {
	const [organisation, setOrganisation] = useState({});
	const [editTarget, setEditTarget] = useState();
	const [companyList, setCompanyList] = useState([]);
	const [targetCompany, setTargetCompany] = useState();
	const { handleSubmit, register, errors, reset } = useForm({
		defaultValues: {
			name: "",
			posId: "",
		},
		mode: "onBlur",
		resolver: yupResolver(AddPOSSchema),
	});

	const columns = [
		{
			name: "",
			width: "80px",
			center: true,
			cell: (row) => (
				<button
					uk-tooltip="Edit Outlet"
					className="uk-icon-button uk-button-primary"
					uk-icon="icon: fa-solid-edit; ratio: 0.6"
					onClick={() => openEditModal(row.posId)}
				/>
			),
		},
		{ name: "Outlet Name", selector: "name", sortable: true },
		{ name: "POS ID", selector: "posId", sortable: true },
		{
			name: "",
			width: "80px",
			cell: (row) => (
				<button
					uk-tooltip="Delete Outlet"
					className="uk-icon-button uk-button-danger"
					uk-icon="icon: fa-solid-trash; ratio: 0.6"
					onClick={() => deletePos({ variables: { id: row.id } })}
				/>
			),
		},
	];

	const { refetch } = useQuery(COMPANIES_QUERY, {
		variables: { organisation: organisation.id || "" },
		fetchPolicy: "cache-and-network",
		onCompleted: ({ companies }) => {
			setCompanyList(companies);
			setTargetCompany(companies[0]);
		},
	});

	useEffect(() => {
		const {
			user: { organisation },
		} = JSON.parse(localStorage.getItem("user"));
		setOrganisation(organisation);

		return () => {
			try {
				UIkit.modal("#pos-modal").$destroy(true);
			} catch (err) {}
		};
	}, []);

	const [addPos] = useMutation(ADD_POS_MUTATION, {
		onCompleted: ({ createPo: { po } }) => {
			Swal.fire({
				icon: "success",
				title: `${po.name} Added!`,
			}).then(() => {
				refetch();
				reset({
					name: "",
					posId: "",
				});
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors[0].message !== "Forbidden")
				Swal.fire({
					icon: "error",
					title: `Unable to add Outlet`,
					text: graphQLErrors[0].message,
				});
		},
	});

	const [editPos] = useMutation(EDIT_POS_MUTATION, {
		onCompleted: ({ updatePo: { po } }) => {
			Swal.fire({
				icon: "success",
				title: `${po.name} Edited!`,
			}).then(() => {
				refetch();
				reset({
					name: "",
					posId: "",
				});
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors[0].message !== "Forbidden")
				Swal.fire({
					icon: "error",
					title: `Unable to edit Outlet`,
					text: graphQLErrors[0].message,
				});
		},
	});

	const [deletePos] = useMutation(DELETE_POS_MUTATION, {
		onCompleted: ({ deletePo: { po } }) => {
			Swal.fire({
				icon: "success",
				title: `${po.name} Removed!`,
			}).then(() => {
				refetch();
				reset({
					name: "",
					posId: "",
				});
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors[0].message !== "Forbidden")
				Swal.fire({
					icon: "error",
					title: `Unable to remove Outlet`,
					text: graphQLErrors[0].message,
				});
		},
	});

	const onSubmit = (values) => {
		Swal.fire({
			title: `${!editTarget ? "Adding" : "Editing"} Outlet`,
			allowOutsideClick: false,
			allowEscapeKey: false,
			onBeforeOpen: () => {
				closePOSModal();
				Swal.showLoading();
			},
			onOpen: () => {
				if (!editTarget)
					addPos({ variables: { companyId: targetCompany.id, ...values } });
				else editPos({ variables: { id: editTarget, ...values } });
			},
		});
	};

	const closePOSModal = () => {
		UIkit.modal("#pos-modal").hide();
		reset();
		setEditTarget(undefined);
	};

	const openEditModal = (posId) => {
		if (targetCompany) {
			const target = targetCompany.POS.find((pos) => pos.posId === posId);
			reset({
				name: target.name,
				posId: target.posId,
			});
			setEditTarget(target.id);
			UIkit.modal("#pos-modal").show();
		}
	};

	const selectCompany = (value) => {
		const target = companyList.find((company) => company.id === value);
		setTargetCompany(target);
	};

	return (
		<div>
			<h1 className="page-header">Branch's Outlet</h1>
			<div className="uk-flex uk-flex-middle uk-margin-bottom">
				{companyList.length > 0 && (
					<SelectSearch
						options={companyList.map(({ id, name }) => {
							return { name: name, value: id };
						})}
						search
						value={targetCompany ? targetCompany.id : undefined}
						placeholder="Select Branch"
						onChange={selectCompany}
					/>
				)}
				{companyList.length > 0 && (
					<button
						uk-tooltip="Add Outlet"
						className="uk-icon-button uk-button-primary uk-margin-left"
						uk-icon="icon: fa-solid-plus; ratio: 0.8"
						uk-toggle="#pos-modal"
					/>
				)}
			</div>
			<div>
				<div className="uk-card uk-card-default uk-card-body">
					<DataTable
						columns={columns}
						data={targetCompany ? targetCompany.POS : []}
						noHeader={true}
						highlightOnHover={true}
						striped={true}
						pagination={true}
					/>
				</div>
			</div>
			<div id="pos-modal" uk-modal="bg-close:false; esc-close: false">
				<div className="uk-modal-dialog uk-modal-body">
					<h2 className="uk-modal-title">{!editTarget ? "Add" : "Edit"} Outlet</h2>
					<form className="uk-form-stacked" onSubmit={handleSubmit(onSubmit)}>
						<TextInput
							label="Outlet Name"
							name="name"
							type="text"
							rules={register}
							errors={errors.name}
						/>
						<TextInput
							label="POS ID"
							name="posId"
							type="text"
							rules={register}
							errors={errors.posId}
						/>
						<p className="uk-text-right uk-margin-medium-top">
							<button
								className="uk-button uk-button-default"
								type="button"
								onClick={closePOSModal}
							>
								Cancel
							</button>
							<button className="uk-button uk-button-primary" type="submit">
								{!editTarget ? "Add" : "Edit"}
							</button>
						</p>
					</form>
				</div>
			</div>
		</div>
	);
};

export default POS;
