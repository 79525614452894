import { gql } from "@apollo/client";

const ADD_EMPLOYEE_MUTATION = gql`
  mutation AddEmployee($companyId: ID!, $name: String!, $employeeId: String!) {
    createEmployee(
      input: {
        data: { company: $companyId, name: $name, employeeId: $employeeId }
      }
    ) {
      employee {
        id
        name
        employeeId
      }
    }
  }
`;

export default ADD_EMPLOYEE_MUTATION;
