import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import Swal from "sweetalert2";
import RESET_PASSWORD_MUTATION from "../../mutations/auth/resetPassword";

const ResetPasswordSchema = yup.object().shape({
	password: yup
		.string()
		.min(8, "Password must contain at least 8 characters")
		.matches(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/,
			"Password must contain at least 1 uppercase, lowercase character and number"
		)
		.required("Password is required"),
	cpassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "Password does not match!")
		.required("Confirm Password is required"),
});

function ResetPassword(props) {
	const params = new URLSearchParams(props.location.search);
	const code = params.get("code");

	const { handleSubmit, register, errors } = useForm({
		mode: "onBlur",
		resolver: yupResolver(ResetPasswordSchema),
	});

	const [doResetPassword] = useMutation(RESET_PASSWORD_MUTATION, {
		onCompleted: (data) => {
			Swal.fire({
				icon: "success",
				title: "Password Resetted!",
			}).then(() => {
				localStorage.setItem("user", JSON.stringify(data.resetPassword));
				window.location = "/";
			});
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				const {
					extensions: {
						exception: {
							data: { message },
						},
					},
				} = graphQLErrors[0];

				Swal.fire({
					icon: "error",
					title: "Unable to reset password",
					text: message[0].messages[0].message,
				});
			}
		},
	});

	const onSubmit = ({ password, cpassword }) => {
		Swal.fire({
			title: "Resetting Password",
			allowOutsideClick: false,
			allowEscapeKey: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
			onOpen: () => {
				doResetPassword({
					variables: {
						password,
						passwordConfirmation: cpassword,
						code,
					},
				});
			},
		});
	};

	return (
		<div className="register uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden">
			<div className="uk-position-cover uk-overlay-default"></div>
			<div
				className="uk-width-large uk-padding-small uk-position-z-index"
				uk-scrollspy="cls: uk-animation-fade"
			>
				<div className="uk-card uk-card-default uk-card-body uk-border-rounded register-card">
					<div className="uk-text-center uk-margin-medium">
						<img width="75%" src="/img/login-logo.svg" alt="Logo" />
					</div>
					<form className="toggle-class" onSubmit={handleSubmit(onSubmit)}>
						<fieldset className="uk-fieldset">
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: lock"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="password"
										placeholder="Password"
										type="password"
										autocomplete="new-password"
										ref={register}
									/>
								</div>
								{errors.password && (
									<p className="validation">{errors.password.message}</p>
								)}
							</div>
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: lock"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="cpassword"
										placeholder="Confirm Password"
										type="password"
										ref={register}
									/>
								</div>
								{errors.cpassword && (
									<p className="validation">{errors.cpassword.message}</p>
								)}
							</div>
							<div className="uk-margin">
								<button
									type="submit"
									className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
								>
									Reset Password
								</button>
								<div className="uk-width-1-1 uk-flex uk-flex-center uk-margin-top">
									<a href="/sign-in">Back to Sign In</a>
								</div>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
