import { gql } from "@apollo/client";

const CREATE_COMPANY_MUTATION = gql`
  mutation NewCompany($name: String!, $organisation: ID!) {
    createCompany(
      input: { data: { name: $name, organisation: $organisation } }
    ) {
      company {
        id
        name
      }
    }
  }
`;

export default CREATE_COMPANY_MUTATION;
