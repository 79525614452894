import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Nav from "../../components/Nav";
import SideNav from "../../components/SideNav";

import Login from "../Login";
import Register from "../Register";
import ForgetPassword from "../ForgetPassword";
import ResetPassword from "../ResetPassword";
import Authorized from "../Authorized";
import XeroRequest from "../XeroRequest";

import routes from "../../routes";
import Swal from "sweetalert2";

toast.configure({
	position: "bottom-center",
	autoClose: 1500,
	newestOnTop: false,
	closeOnClick: true,
	rtl: false,
	pauseOnVisibilityChange: false,
	draggable: false,
	pauseOnHover: false,
	toastClassName: "copy-toast",
	bodyClassName: "copy-toast-body",
});

const isAuthenticated = (to, from, next) => {
	const token = localStorage.getItem("user");
	if (to.match.path === "/sign-in") {
		if (token) next.redirect("/");
	}

	if (to.meta.requireAuth) {
		if (token) next();
		// Swal.fire({
		//   icon: "info",
		//   title: "Session has expired!",
		//   text: "Please sign in again",
		//   onClose: () => {
		else next.redirect("/sign-in");
		//   },
		// });
	} else next();
};

function App() {
	return (
		<div className="App">
			<GuardProvider guards={[isAuthenticated]}>
				<Switch>
					<GuardedRoute path="/sign-in" component={Login} exact />
					<GuardedRoute path="/sign-up" component={Register} exact />
					<GuardedRoute path="/forget-password" component={ForgetPassword} exact />
					<GuardedRoute path="/reset-password" component={ResetPassword} exact />
					<GuardedRoute path="/xero/auth/success" component={Authorized} exact />
					{routes.map(({ path, component: Component, exact }, index) => (
						<GuardedRoute
							key={index}
							path={path}
							exact={exact}
							meta={{ requireAuth: true }}
							render={(props) => (
								<>
									<Nav />
									<SideNav routes={routes} />
									<div id="content" data-uk-height-viewport="expand: true">
										<div className="uk-container uk-container-expand">
											<Component {...props} />
										</div>
									</div>
								</>
							)}
						/>
					))}
					<GuardedRoute
						path="/xero/auth/request/:company"
						component={XeroRequest}
						exact
					/>
					<Route path="*" render={(props) => <Redirect to="/" />} />
				</Switch>
			</GuardProvider>
		</div>
	);
	// : (
	//   <div className="App">
	//     <Switch>
	//       <Redirect from="/" exact to="/sign-in" />
	//       <Route path="/sign-in" component={Login} exact />
	//     </Switch>
	//   </div>
	// );
}

export default App;
