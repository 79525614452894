import { gql } from "@apollo/client";

const EDIT_EMPLOYEE_MUTATION = gql`
  mutation EditEmployee($id: ID!, $name: String!, $employeeId: String!) {
    updateEmployee(
      input: {
        where: { id: $id }
        data: { name: $name, employeeId: $employeeId }
      }
    ) {
      employee {
        id
        name
        employeeId
      }
    }
  }
`;

export default EDIT_EMPLOYEE_MUTATION;
