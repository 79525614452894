import { gql } from "@apollo/client";

const EDIT_COMPANY_MUTATION = gql`
  mutation EditCompany($companyId: ID!, $name: String!, $email: String!) {
    updateCompany(
      input: { where: { id: $companyId }, data: { name: $name, email: $email } }
    ) {
      company {
        id
        name
      }
    }
  }
`;

export default EDIT_COMPANY_MUTATION;
