import { gql } from "@apollo/client";

const EDIT_PAYBOY_ACCESS_MUTATION = gql`
  mutation EditPayboyAccess($companyId: ID!, $access_token: String!) {
    updateCompany(
      input: {
        where: { id: $companyId }
        data: { PayboyAccessToken: $access_token }
      }
    ) {
      company {
        id
        name
      }
    }
  }
`;

export default EDIT_PAYBOY_ACCESS_MUTATION;
