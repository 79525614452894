import React from "react";
import { Link, useLocation } from "react-router-dom";

const SideNav = ({ routes }) => {
  let { pathname } = useLocation();
  return (
    <aside id="left-col" className="uk-light uk-visible@m">
      <Link className="left-logo uk-flex uk-flex-middle" to="/">
        <span>Dashboard</span>
      </Link>
      <div className="left-nav-wrap">
        <ul
          className="uk-nav uk-nav-default uk-nav-parent-icon uk-margin-top"
          data-uk-nav
        >
          {routes.map(({ path, name, icon }, index) => (
            <li
              key={`nav-${index}`}
              className={pathname === path ? "uk-active" : ""}
            >
              <Link to={path}>
                <span
                  data-uk-icon={`icon: ${icon}`}
                  className="uk-margin-small-right"
                ></span>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default SideNav;
