import { gql } from "@apollo/client";

const FORGOT_PASSWORD_MUTATION = gql`
	mutation ForgotPassword($email: String!) {
		forgotPassword(email: $email) {
			ok
		}
	}
`;

export default FORGOT_PASSWORD_MUTATION;
