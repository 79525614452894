import { gql } from "@apollo/client";

const LOGIN_MUTATION = gql`
	mutation Login($input: UsersPermissionsLoginInput!) {
		login(input: $input) {
			jwt
			user {
				id
				username
				email
				organisation {
					id
					name
					FTP
				}
			}
		}
	}
`;

export default LOGIN_MUTATION;
