import { gql } from "@apollo/client";

const CHANGE_PASSWORD_MUTATION = gql`
	mutation ChangePassword($input: ChangePassword!) {
		changePassword(input: $input) {
			jwt
			user {
				id
				username
				email
				organisation {
					id
					name
					FTP
				}
			}
		}
	}
`;

export default CHANGE_PASSWORD_MUTATION;
