import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import SelectSearch from "react-select-search/dist/cjs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { TextInput } from "../../components/Form";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";

import Swal from "sweetalert2";
import UIkit from "uikit";
import COMPANIES_QUERY from "../../queries/company/companies";
import ADD_EMPLOYEE_MUTATION from "../../mutations/employee/employee";
import EDIT_EMPLOYEE_MUTATION from "../../mutations/employee/edit";
import DELETE_EMPLOYEE_MUTATION from "../../mutations/employee/delete";

const AddEmployeeSchema = yup.object().shape({
  name: yup.string().required("Employee Name is required"),
  employeeId: yup.string().required("Employee ID is required"),
});

const Employee = (props) => {
  const [organisation, setOrganisation] = useState({});
  const [editTarget, setEditTarget] = useState();
  const [companyList, setCompanyList] = useState();
  const [targetCompany, setTargetCompany] = useState();

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: {
      name: "",
      employeeId: "",
    },
    mode: "onBlur",
    resolver: yupResolver(AddEmployeeSchema),
  });

  const { refetch } = useQuery(COMPANIES_QUERY, {
    variables: { organisation: organisation.id || "" },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ companies }) => {
      setCompanyList(companies);
      setTargetCompany(companies[0]);
    },
  });

  const columns = [
    {
      name: "",
      width: "80px",
      center: true,
      cell: (row) => (
        <button
          uk-tooltip="Edit Employee"
          className="uk-icon-button uk-button-primary"
          uk-icon="icon: fa-solid-edit; ratio: 0.6"
          onClick={() => openEditModal(row.employeeId)}
        />
      ),
    },
    { name: "Employee Name", selector: "name", sortable: true },
    { name: "Employee ID", selector: "employeeId", sortable: true },
    {
      name: "",
      width: "80px",
      cell: (row) => (
        <button
          uk-tooltip="Delete Employee"
          className="uk-icon-button uk-button-danger"
          uk-icon="icon: fa-solid-trash; ratio: 0.6"
          onClick={() => deleteEmployee({ variables: { id: row.id } })}
        />
      ),
    },
  ];

  useEffect(() => {
    const {
      user: { organisation },
    } = JSON.parse(localStorage.getItem("user"));
    setOrganisation(organisation);

    return () => {
      try {
        UIkit.modal("#employee-modal").$destroy(true);
      } catch (err) {}
    };
  }, []);

  const [addEmployee] = useMutation(ADD_EMPLOYEE_MUTATION, {
    onCompleted: ({ createEmployee: { employee } }) => {
      Swal.fire({
        icon: "success",
        title: `${employee.name} Added!`,
      }).then(() => {
        refetch();
        reset();
      });
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors[0].message !== "Forbidden")
        Swal.fire({
          icon: "error",
          title: `Unable to add Employee`,
          text: graphQLErrors[0].message,
        });
    },
  });

  const [editEmployee] = useMutation(EDIT_EMPLOYEE_MUTATION, {
    onCompleted: ({ updateEmployee: { employee } }) => {
      Swal.fire({
        icon: "success",
        title: `${employee.name} Edited!`,
      }).then(() => {
        refetch();
        reset();
      });
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors[0].message !== "Forbidden")
        Swal.fire({
          icon: "error",
          title: `Unable to edit Employee`,
          text: graphQLErrors[0].message,
        });
    },
  });

  const [deleteEmployee] = useMutation(DELETE_EMPLOYEE_MUTATION, {
    onCompleted: ({ deleteEmployee: { employee } }) => {
      Swal.fire({
        icon: "success",
        title: `${employee.name} Removed!`,
      }).then(() => {
        refetch();
        reset();
      });
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors[0].message !== "Forbidden")
        Swal.fire({
          icon: "error",
          title: `Unable to remove Employee`,
          text: graphQLErrors[0].message,
        });
    },
  });

  const onSubmit = (values) => {
    Swal.fire({
      title: `${!editTarget ? "Adding" : "Editing"} Employee`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => {
        closeEmployeeModal();
        Swal.showLoading();
      },
      onOpen: () => {
        if (!editTarget)
          addEmployee({
            variables: { companyId: targetCompany.id, ...values },
          });
        else editEmployee({ variables: { id: editTarget, ...values } });
      },
    });
  };

  const closeEmployeeModal = () => {
    UIkit.modal("#employee-modal").hide();
    reset();
    setEditTarget(undefined);
  };

  const openEditModal = (employeeId) => {
    if (targetCompany) {
      const target = targetCompany.employees.find(
        (employee) => employee.employeeId === employeeId
      );
      reset({
        name: target.name,
        employeeId: target.employeeId,
      });
      setEditTarget(target.id);
      UIkit.modal("#employee-modal").show();
    }
  };

  const selectCompany = (value) => {
    const target = companyList.find((company) => company.id === value);
    setTargetCompany(target);
  };

  return (
    <div>
      <h1 className="page-header">Company's Employees</h1>
      <div className="uk-flex uk-flex-middle uk-margin-bottom">
        {companyList && (
          <SelectSearch
            options={companyList.map(({ id, name }) => {
              return { name: name, value: id };
            })}
            search
            value={targetCompany.id}
            placeholder="Select Company"
            onChange={selectCompany}
          />
        )}
        <button
          uk-tooltip="Add Employee"
          className="uk-icon-button uk-button-primary uk-margin-left"
          uk-icon="icon: fa-solid-plus; ratio: 0.8"
          uk-toggle="#employee-modal"
        />
      </div>
      <div>
        <div className="uk-card uk-card-default uk-card-body">
          <DataTable
            columns={columns}
            data={targetCompany ? targetCompany.employees : []}
            noHeader={true}
            highlightOnHover={true}
            striped={true}
            pagination={true}
          />
        </div>
      </div>
      <div id="employee-modal" uk-modal="bg-close:false; esc-close: false">
        <div className="uk-modal-dialog uk-modal-body">
          <h2 className="uk-modal-title">
            {!editTarget ? "Add" : "Edit"} Employee
          </h2>
          <form className="uk-form-stacked" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label="Employee Name"
              name="name"
              type="text"
              rules={register}
              errors={errors.name}
            />
            <TextInput
              label="Employee ID"
              name="employeeId"
              type="text"
              rules={register}
              errors={errors.employeeId}
            />
            <p className="uk-text-right uk-margin-medium-top">
              <button
                className="uk-button uk-button-default"
                type="button"
                onClick={closeEmployeeModal}
              >
                Cancel
              </button>
              <button className="uk-button uk-button-primary" type="submit">
                {!editTarget ? "Add" : "Edit"}
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Employee;
