import Company from "./containers/Company";
import POS from "./containers/POS";
import Employee from "./containers/Employee";

const routes = [
	{
		path: "/",
		name: "Branch",
		component: Company,
		exact: true,
		icon: "fa-solid-building",
	},
	{
		path: "/pos",
		name: "Outlet",
		component: POS,
		exact: true,
		icon: "fa-solid-cash-register",
	},
	// {
	//   path: "/employee",
	//   name: "Employee",
	//   component: Employee,
	//   exact: true,
	//   icon: "fa-solid-users",
	// },
];

export default routes;
