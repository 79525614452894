import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import Swal from "sweetalert2";

import LOGIN_MUTATION from "../../mutations/auth/login";

const LoginSchema = yup.object().shape({
	email: yup.string().required("Email Address is required"),
	password: yup.string().required("Password is required"),
});

function Login(props) {
	const { handleSubmit, register, errors } = useForm({
		mode: "onBlur",
		resolver: yupResolver(LoginSchema),
	});

	const [login] = useMutation(LOGIN_MUTATION, {
		onCompleted: (data) => {
			Swal.close();
			localStorage.setItem("user", JSON.stringify(data.login));
			window.location = "/";
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				const {
					extensions: {
						exception: {
							data: { message },
						},
					},
				} = graphQLErrors[0];
				Swal.fire({
					icon: "error",
					title: "Unable to sign in",
					text: message[0].messages[0].message,
				});
			}
		},
	});

	const onSubmit = (values) => {
		const { email, password } = values;
		Swal.fire({
			title: "Signing In...",
			allowOutsideClick: false,
			allowEscapeKey: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
			onOpen: () => {
				login({ variables: { input: { identifier: email, password } } });
			},
		});
	};

	return (
		<div className="login uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden">
			<div className="uk-position-cover uk-overlay-default"></div>
			<div
				className="uk-width-large uk-padding-small uk-position-z-index"
				uk-scrollspy="cls: uk-animation-fade"
			>
				<div className="uk-card uk-card-default uk-card-body uk-border-rounded">
					<div className="uk-text-center uk-margin-medium">
						<img width="100%" src="/img/foodzaps.jpg" alt="Logo" />
					</div>
					<form className="toggle-class" onSubmit={handleSubmit(onSubmit)}>
						<fieldset className="uk-fieldset">
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: user"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="email"
										placeholder="Email Address"
										type="text"
										ref={register}
									/>
								</div>
								{errors.email && (
									<p className="validation">{errors.email.message}</p>
								)}
							</div>
							<div className="uk-margin-small">
								<div className="uk-inline uk-width-1-1">
									<span
										className="uk-form-icon uk-form-icon-flip"
										data-uk-icon="icon: lock"
									></span>
									<input
										className="uk-input uk-border-pill"
										name="password"
										placeholder="Password"
										type="password"
										ref={register}
									/>
								</div>
								{errors.password && (
									<p className="validation">{errors.password.message}</p>
								)}
							</div>
							<div className="uk-margin">
								<button
									type="submit"
									className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
								>
									LOG IN
								</button>
								<div className="uk-width-1-1 uk-flex uk-flex-center uk-margin-top">
									<a href="/sign-up">Register An Account</a>
								</div>
								<div className="uk-width-1-1 uk-flex uk-flex-center uk-margin-top">
									<a href="/forget-password">Forget your password?</a>
								</div>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Login;
