import { gql } from "@apollo/client";

const RESET_PASSWORD_MUTATION = gql`
	mutation ResetPassword(
		$password: String!
		$passwordConfirmation: String!
		$code: String!
	) {
		resetPassword(
			password: $password
			passwordConfirmation: $passwordConfirmation
			code: $code
		) {
			jwt
			user {
				id
				username
				email
				organisation {
					id
					name
					FTP
				}
			}
		}
	}
`;

export default RESET_PASSWORD_MUTATION;
