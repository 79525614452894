import React from "react";

export const TextInput = ({ label, name, type, rules, errors, disabled }) => {
  return (
    <div className="uk-margin">
      {label && <label className="uk-form-label">{label}</label>}
      <div className="uk-form-controls">
        <input
          className={`uk-input ${errors ? "uk-form-danger" : ""}`}
          name={name}
          type={type}
          ref={rules}
          disabled={disabled}
        />
      </div>
      {errors && <span className="validation">{errors.message}</span>}
    </div>
  );
};

export const SelectInput = ({
  label,
  name,
  data,
  rules,
  errors,
  disabled,
  handleChange,
  initialEmpty = true,
}) => {
  return (
    <div className="uk-margin">
      {label && <label className="uk-form-label">{label}</label>}
      <div className="uk-form-controls">
        <select
          className="uk-select"
          name={name}
          ref={rules}
          onChange={handleChange}
          disabled={disabled}
        >
          {initialEmpty && <option></option>}
          {data &&
            data.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
        </select>
      </div>
      {errors && <span className="validation">{errors.message}</span>}
    </div>
  );
};
