import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Swal from "sweetalert2";

const cache = new InMemoryCache();

const httpLink = new createHttpLink({
	uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});

const unauthorizedLink = onError(({ graphQLErrors }) => {
	let forbidden = false;
	if (graphQLErrors) {
		console.log(graphQLErrors);
		graphQLErrors.map(({ message, path }) => {
			if (message === "Forbidden" || message === "Invalid token.") forbidden = true;
		});
	}

	if (forbidden) {
		Swal.fire({
			icon: "info",
			title: "Session has expired!",
			text: "Please sign in again",
			onClose: () => {
				localStorage.removeItem("user");
				window.location = "/sign-in";
			},
		});
	}
});

const authLink = setContext((_, { headers }) => {
	const auth = localStorage.getItem("user");

	if (auth) {
		const { jwt } = JSON.parse(auth);
		return {
			headers: {
				...headers,
				authorization: jwt ? `Bearer ${jwt}` : "",
			},
		};
	}

	return { headers };
});

const link = from([unauthorizedLink, authLink, httpLink]);

const client = new ApolloClient({
	cache,
	link,
});

export default client;
