import { gql } from "@apollo/client";

const EDIT_POS_MUTATION = gql`
  mutation EditPOS($id: ID!, $name: String!, $posId: String!) {
    updatePo(
      input: { where: { id: $id }, data: { name: $name, posId: $posId } }
    ) {
      po {
        id
        name
        posId
      }
    }
  }
`;

export default EDIT_POS_MUTATION;
