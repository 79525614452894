import React from "react";

function XeroRequest(props) {
	const { company } = props.match.params;

	return (
		<div className="register uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden">
			<div className="uk-position-cover uk-overlay-default"></div>
			<div
				className="uk-width-large uk-padding-small uk-position-z-index"
				uk-scrollspy="cls: uk-animation-fade"
			>
				<div className="uk-card uk-card-default uk-card-body uk-border-rounded register-card">
					<div className="uk-text-center uk-margin-medium">
						<img width="75%" src="/img/login-logo.svg" alt="Logo" />
					</div>
					<a
						className="uk-button uk-button-default uk-width-1-1 uk-padding-small"
						href={`https://xero.foodzaps.com/xero/auth/request?companyId=${company}`}
					>
						<img className="uk-margin-right" width="40" src="/img/xero.png" />
						Sign In with Xero
					</a>
				</div>
			</div>
		</div>
	);
}

export default XeroRequest;
